import { shared } from './shared'

export const test = {
  environmentName: 'test',
  baseUrl: 'https://app.test.tellow.nl/',
  production: false,
  verboseDebugOutput: true,
  deployTestScreens: true,
  firebase: {
    apiKey: 'AIzaSyDJ4FDrnNmkb-qC47es8gs87mCMxxHyHok',
    authDomain: 'hellotellow-test.firebaseapp.com',
    databaseURL: 'https://hellotellow-test.firebaseio.com',
    projectId: 'hellotellow-test',
    storageBucket: '',
    messagingSenderId: '675828645530',
    appId: '1:675828645530:web:ea03ab3db2ba3db7',
    measurementId: 'G-CZBFJGS3TB',
    vapidKey: 'BAztw6QxBuo2e2anI7FR6qIeND8RJY9JPg1gQF2yJGGEzVev2Mex_9Qb7t9cP0VMtJ7ysuuHoDjRI-3zitnm1pY',
  },
  intercomAppId: 'plxyjfy3',
  // Test endpoint with production credentials
  apiUrl: 'https://api.test.tellow.nl',
  // clientId: '4_5k3pdmz4g10kk044k08kwkog8kg0w0c48888c8ko8g08cgskws',
  // clientSecret: '1w8h5f9clpes4gggsckgok08c80o4w0ss8okcw00ccowwk4o4w'
  kvkApiUrl: 'https://kvk-api-dot-tellow-backend-test.appspot.com',
  billingServiceApiUrl: 'https://billing.test.tellow.nl/api',
  stripeAntiCancellationCoupon: 'GhwFQ3WR',
  taxServiceApiUrl: 'https://tax.test.tellow.nl/api',
  bankServiceApiUrl: 'https://bank.test.tellow.nl/api',
  segmentApiKey: 'uOEPDt2NbhC5qFFiBDddap8aa9dggC1o',
  userflowToken: 'ct_7vlkwrdpgnfwzbhthwpses2d5y',
  paymentsServiceApiUrl: 'https://payments.test.tellow.nl/api',
  agerasFinanceAppUrl: 'https://app.agerasfinancestaging.com',
  integrationsServiceApiUrl: 'https://integrations.test.tellow.nl',
  swanBankingUrl: 'https://banking.sandbox.swan.io/projects/e252852c-61e4-4615-aff9-a549af776912',
  laravelServiceApiUrl: 'https://api2.test.tellow.nl',
  recaptchaSiteKey: '6Ldr3hEaAAAAAB9K0p2r4Ki2p1eu_ZXKZLMwwI0G',
  ...shared,
}
